var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "差价详情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-fixed-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (aod, index) {
    return _c('aod-detail-item', {
      key: index,
      attrs: {
        "item": aod
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.handleShowQrcode
    }
  }, [_vm._v("查看收款码")])], 1), _c('my-dialog', {
    attrs: {
      "title": "收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showQrcodeDialog,
      callback: function callback($$v) {
        _vm.showQrcodeDialog = $$v;
      },
      expression: "showQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "qrcode"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }