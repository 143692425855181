<template>
  <div class="aod-detail-item">
    <van-image class="image" :src="item.image" />
    <div class="info">
      <div>{{ item.name }}</div>
      <!-- <div class="sub-title">规格：1 * {{ item.size }}</div> -->
      <div v-if="item.good_diff_quantity > 0" class="sub-title">完好的差：<van-tag type="success">{{ `${item.good_diff_quantity}${item.least_unit}` }}</van-tag></div>
      <div v-if="item.bad_diff_quantity > 0" class="sub-title">损坏的差：<van-tag type="danger">{{ `${item.bad_diff_quantity}${item.least_unit}` }}</van-tag></div>
      <div class="sub-title">单价：<span class="amount">￥{{ item.price }}</span></div>
      <div class="sub-title">差价金额：<span class="amount">￥{{ item.diff_amount }}</span></div>
      <div class="sub-title">验收备注：{{ item.accept_remark }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .aod-detail-item {
    display: flex;
    background-color: #fff;
    padding: 10px;
  }
  .image {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
  }
  .info {
    margin-left: 10px;
  }
  .sub-title {
    font-size: 12px;
    color: #989898;
  }
  .amount {
    color: #ee0a24;
  }
</style>
