<template>
  <div>
    <my-nav-bar
      title="差价详情"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-fixed-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="list">
        <aod-detail-item v-for="(aod, index) in list" :key="index" :item="aod" />
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <van-button block square @click="handleShowQrcode">查看收款码</van-button>
    </div>

    <my-dialog
      v-model="showQrcodeDialog"
      title="收款码"
      :show-cancel-button="false"
    >
      <div class="qrcode">
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getAodDetail } from '@/api/delivery_order'
import AodDetailItem from './components/aod-detail-item'
import qrcode from 'qrcode'
import MyDialog from '@/components/my-dialog'
// import GeneratePaymentKey from '@/utils/payment-key'
export default {
  components: { myNavBar, AodDetailItem, MyDialog },
  data() {
    return {
      query: {
        client_id: this.$route.query.client_id
      },
      list: [],
      showEmpty: false,
      showQrcodeDialog: false,
      qrcodeData: null
    }
  },
  created() {
    this.beginLoad()
    getAodDetail(this.query).then(res => {
      this.endLoad()
      this.list = res.data
      this.showEmpty = this.list.length === 0
    })
  },
  methods: {
    handleShowQrcode() {
      const url = process.env.VUE_APP_PAYMENT_URL + 'after-diff/?after_order_num=' + this.$route.query.after_order_num
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showQrcodeDialog = true
        this.qrcodeData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .list .aod-detail-item {
    margin-bottom: 10px;
  }
  .list .aod-detail-item:last-child {
    margin-bottom: 0!important;
  }
  .qrcode {
    text-align: center;
  }
</style>
