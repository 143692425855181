var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aod-detail-item"
  }, [_c('van-image', {
    staticClass: "image",
    attrs: {
      "src": _vm.item.image
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', [_vm._v(_vm._s(_vm.item.name))]), _vm.item.good_diff_quantity > 0 ? _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("完好的差："), _c('van-tag', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(_vm._s("".concat(_vm.item.good_diff_quantity).concat(_vm.item.least_unit)))])], 1) : _vm._e(), _vm.item.bad_diff_quantity > 0 ? _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("损坏的差："), _c('van-tag', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v(_vm._s("".concat(_vm.item.bad_diff_quantity).concat(_vm.item.least_unit)))])], 1) : _vm._e(), _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("单价："), _c('span', {
    staticClass: "amount"
  }, [_vm._v("￥" + _vm._s(_vm.item.price))])]), _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("差价金额："), _c('span', {
    staticClass: "amount"
  }, [_vm._v("￥" + _vm._s(_vm.item.diff_amount))])]), _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("验收备注：" + _vm._s(_vm.item.accept_remark))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }